import React from "react"
import Hero from "../../microcomponents/hero/hero"
import ClientLogos from "../../microcomponents/clientlogos/clientlogos"
import HowitWorks from "../../microcomponents/howitworks/howitworks"
import TechStacks from "../../microcomponents/techstacks/techstacks"
import TopPredictions from "../../microcomponents/toppredictions/toppredictions"
import ClientResults from "../../microcomponents/clientresults/clientresults"
import HeaderV2 from "../headerv2/headerv2"
import "./homev2.scss"
import FrequentlyAskedQuestions from "../frequentlyaskedquestions/frequentlyaskedquestions"
import { faqs } from "../../utilities/faqs"

export const HomeV2 = () => {
  return (
    <div className={`control`}>
      <HeaderV2 version="v2" />
      <Hero
        version="v2"
        className={`blueeclipse`}
        description="
      <p>A <b> prediction engine</b> that captures winning A/B tests from <b>78,000</b> websites.<br class='hidedesktop hidetablet hidemobile'/> Plus, a full-service team that handles design and dev. More wins. Faster results.<br class='hidedesktop hidetablet hidemobile'/> Performance pricing. <b>No fee until we deliver.</b></p>
      "
        heroImage={`heroimagev2.webp`}
        video={`//res.cloudinary.com/spiralyze/video/upload/spiralyze2022/VIDEOS/Hero_animation_Single_screen_version.mp4`}
        sourceFolder={`homepage`}
        cloudinaryPath={`https://res.cloudinary.com/spiralyze/image/upload/f_auto/spiralyze2022`}
      />
      <ClientLogos version="v2 gray-bg" />
      <HowitWorks
        version="v2"
        section1Description="<p>Spiralyze scrapes A/B test data from<br /> the 78,000 sites that actively test to<br /> learn what wins. Machine learning<br /> uses that data to predict what wins on<br /> your sites.</p><p>Get 3X higher win rates than legacy<br/> A/B testing.</p>"
        section1Image="howitworksimage1.webp"
        section2Cta="Full-Service"
        section2Description="<p>Design, copy, dev, QA, analytics, and<br/> PM. All software included.<br/> First test live in just 2 weeks.<br/> All implementation handled by a<br class='hidemobile'/> team of 170 A/B testing specialists.</p>"
        section3Cta="Pricing"
      />
      <TopPredictions version="v2" />
      <TechStacks version="v2" heading="Experience across<br/> tech stacks" />

      <ClientResults version="v2" />
      <FrequentlyAskedQuestions faqs={faqs} />
    </div>
  )
}
